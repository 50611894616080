import React from "react";
import styled from "@emotion/styled";
import { Table } from "components/ui";
import { CheckOutlined } from "@ant-design/icons";
import { useGetOrderDataByFilter } from 'hooks/api';

const isUpdateCell = ({ value }: any) => {
  if (value === true) {
    return <UplodadText>Done</UplodadText>
  } else {
    return <NoUploadText>no upload</NoUploadText>
  }
}

const zipCode = ({ value }: any) => {
  return `${value?.slice(1)}`;
  // return `${parseInt(value)}`;
};

const isPhoto = ({ value }: any) => {
  if (Boolean(value)) {
    return <AlreadyDownload />;
  }
  return <PreDownload />;
};

const AllOrders: React.FC<any> = ({ getCheckListInLMS, value,  tab, filterOptions }) => {  
  const { data } = useGetOrderDataByFilter(filterOptions);
  const columnData = [
    { 
      Header : () => null,
      id: 'expander',
      //@ts-ignore
      Cell: ({row}) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ?  '👇' : '👉'}
        </span>
      )
    },
    {
      accessor: "id",
      Header: "DBNumber",
      id: "id",
      // disableSortBy: true,
      disableFilters: true,
      disableCheckbox: true,
    },
    {
      accessor: "payhistory",
      Header: "주문번호",
      id: "orderNumber",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "invoiceNumber",
      Header: "송장번호",
      id: "invoiceNumber",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "user[3]",
      Header: "회원번호",
      id: "photyId",
      disableFilters: true,
    },
    {
      accessor: "user[0]",
      Header: "고객명",
      id: "name",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "phone",
      Header: "전화번호",
      id: "phone",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "userTier",
      Header: "회원등급",
      id: "userTier",
      disableSortBy: true,
      // Filter: SelectColumnFilter,
      // filter: "equals",
      disableFilters: true,
    },
    {
      accessor: "year",
      Header: "연도",
      id: "year",
      disableFilters: true,
    },
    {
      accessor: "month",
      Header: "월",
      id: "month",
      disableFilters: true,
    },
    {
      accessor: "zipCode",
      Header: "우편번호",
      id: "zipCode",
      disableSortBy: true,
      disableFilters: true,
      Cell: zipCode,
    },
    {
      accessor: `address[1]`,
      Header: "주소",
      id: "defaultAddress",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: `address[2]`,
      Header: "상세 주소",
      id: "detailAddress",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: `allAddress`,
      Header: "전체 주소",
      id: "totalAddress",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "subscribes",
      Header: "구독타입",
      id: "subscribes",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "NumberOfPhoto",
      Header: "사진 수",
      id: "photos",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "NumberOfPage",
      Header: "페이지 수",
      id: "pages",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "order_type_id",
      Header: "주문타입",
      id: "order_type_id",
      disableSortBy: true,
      // Filter: SelectColumnFilter,
      // filter: "equals",
      disableFilters: true,
    },
    {
      accessor: "isUpdate",
      Header: "업로드 유무",
      id: "is_update",
      disableSortBy: true,
      disableFilters : true,
      Cell : isUpdateCell,
    },
    {
      accessor: "totalprice",
      Header: "금액",
      id: "totalprice",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "deliveryStage",
      Header: "배송상태",
      id: "deliveryStage",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      accessor: "download_at",
      Header: "다운로드",
      id: "download_at",
      disableSortBy: true,
      // Cell: isPhoto,
      disableFilters: true,
      // Filter: SelectColumnFilter,
      // filter: "equals",
    },
  ];

  const hiddenColumn = [
    'id', 
    "totalprice", 
    "defaultAddress", 
    "zipCode", 
    "detailAddress", 
    "deliveryStage",
    "totalAddress"
  ];

  const getCheckList = async (e: any) => {
    getCheckListInLMS(e);
  };
  
  return (
    <Container>
      <Table
        columnData={columnData}
        bodyData={data || []}
        getCheckList={getCheckList}
        filters={["name", "phone"]}
        filter={value}
        hiddenColumn={hiddenColumn}
      />
    </Container>
  );
};

export default AllOrders;

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
`;
const AlreadyDownload = styled(CheckOutlined)`
  color: green;
  font-size: 20px;
  font-weight: 500;
`;
const PreDownload = styled(CheckOutlined)`
  color: lightgray;
  font-size: 20px;
  font-weight: 500;
`;
const UplodadText = styled.p`
  font-size: 12px;
  color: green;
`;
const NoUploadText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: red;
`;