import styled from '@emotion/styled';
import { DownloadButton } from "components/ui";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import FolderNameInput from "../FolderNameInput";
import DeliveryUpload  from "../DeliveryFileUpload";

const TopButtonContainer:React.FC<any> = ({ checkList, tab }) => {
  const [ showModal, setShowModal ] = useState(false);
  const csvLinkref = useRef<any>(null);
  const today = new Date();

  const CSV_HEADERS = [
    { label: "수령자명", key: "name" },
    { label: "우편번호", key: "zipCode" },
    { label: "수령자 연락처", key: "phone" },
    { label: "수령자 주소", key: "totalAddress" },
    // { label: "수령자 주소", key: "defaultAddress" },
    // { label: "수령자 상세주소", key: "detailAddress" },

  ];
  // console.log('check???', checkList)
  const downloadInvoice = (e: any) => {
    e.preventDefault();
    csvLinkref?.current?.link.click();
  };

  const getFileName = () => {
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    return `${year}-${month}-${day}`;
  };

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    if (showModal === true) return setShowModal(false)
  }
  // console.log('here???',checkList)
  
  return (
    <>
      <ButtonContaier>
        <CSVLink
          data={ checkList || []}
          headers={CSV_HEADERS}
          filename={getFileName()}
          ref={csvLinkref}
        />
        <DownloadButton
          icon="download"
          layoutVariant="outlined"
          variant="default"
          size="tiny"
          onClick={downloadInvoice}
        >
          배송명단 다운로드
        </DownloadButton>
        <div>
          <DownloadButton
            icon="file"
            layoutVariant="contained"
            variant="default"
            size="tiny"
            onClick={openModal}
          >
            사진파일 폴더링
          </DownloadButton>
          {showModal && <FolderNameInput showModal={showModal} closeModal={closeModal} checkData={checkList} />}
        </div> 
        <DeliveryUpload tab={tab} />
      </ButtonContaier>
    </>
  )
};

export default TopButtonContainer;


const ButtonContaier = styled.div`
  width: 450px;
  height: 100%;
  text-align: center;
  margin-left: auto;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
`;
